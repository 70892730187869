<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async mapData (filterObject) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/map/support'
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      url: URL,
      data: filterObject
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async searchMap (filterObject) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/map/support?admin=true'
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      url: URL,
      data: filterObject
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
