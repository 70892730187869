<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getCustomSearchMetrics (filterObject, tags) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const terms = []
    tags.forEach(element => {
      terms.push(element.id)
    })
    const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/search/custom'
    let data
    if (filterObject.filterType !== 5) {
      if (filterObject.districtID) {
        data = {
          filterType: filterObject.filterType,
          terms: terms,
          districtID: filterObject.districtID
        }
      } else {
        data = {
          filterType: filterObject.filterType,
          terms: terms
        }
      }
    } else if (filterObject.filterType === 5) {
      if (filterObject.districtID) {
        data = {
          filterType: filterObject.filterType,
          startDate: filterObject.startDate,
          endDate: filterObject.endDate,
          terms: terms,
          districtID: filterObject.districtID
        }
      } else {
        data = {
          filterType: filterObject.filterType,
          startDate: filterObject.startDate,
          endDate: filterObject.endDate,
          terms: terms
        }
      }
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: data,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      // eslint-disable-next-line
      console.error(error.response)
      return error.response
    }
  }
}
</script>
