<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getBehaviourMetrics (filterObject) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour'
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: filterObject,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getBehaviourListings (filterObject, sortby) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    if (sortby) {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/listings?count=10&sortcat=' + sortby
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/listings?count=10'
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    }
  },
  async getBehaviourVideos (filterObject, sortby) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    if (sortby) {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/videos?count=10&sortcat=' + sortby
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/videos?count=10'
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    }
  },
  async getBehaviourResources (filterObject, sortby) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    if (sortby) {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/resources?count=10&sortcat=' + sortby
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/resources?count=10'
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    }
  },
  async getBehaviourListingsPage (filterObject, cursorType, cursor, rowsPerPage, sortby) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    if (sortby) {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/listings?count=' + rowsPerPage + '&' + cursorType + '=' + cursor + '&sortcat=' + sortby
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/listings?count=' + rowsPerPage + '&' + cursorType + '=' + cursor
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    }
  },
  async getBehaviourVideosPage (filterObject, cursorType, cursor, rowsPerPage, sortby) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    if (sortby) {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/videos?count=' + rowsPerPage + '&' + cursorType + '=' + cursor + '&sortcat=' + sortby
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/videos?count=' + rowsPerPage + '&' + cursorType + '=' + cursor
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    }
  },
  async getBehaviourResourcesPage (filterObject, cursorType, cursor, rowsPerPage, sortby) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    if (sortby) {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/resources?count=' + rowsPerPage + '&' + cursorType + '=' + cursor + '&sortcat=' + sortby
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      const URL = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/behaviour/resources?count=' + rowsPerPage + '&' + cursorType + '=' + cursor
      const ops = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: filterObject,
        url: URL
      }
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      try {
        const res = await axios(ops)
        return res
      } catch (error) {
        return error.response
      }
    }
  }
}
</script>
